import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { CUSTOMERS_PATH, SHIPPING_METHOD_PRIORITY_SHIPPING } from 'definitions'
import { isNull } from 'helpers/Common'
import { removeEmptyItems } from 'helpers/Array'
import { toInt } from 'helpers/String'
import Checkbox from 'components/Common/Form/Checkbox'
import ContentContainer from 'components/Common/ContentContainer'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import { mobxEmailList } from 'helpers/Validation'
import Addresses from './Addresses/'
import Card from 'components/Common/Card'
import Contacts from './Contacts/'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'
import SpecialProducts from './SpecialProducts/'

const rules = 'required'

class CustomerForm extends Component {
  constructor(props) {
    super(props)
    const {
      customerRepository: {
        CRUD: {
          data: {
            name = '',
            groups,
            defaultStore,
            status = 'ACTIVE',
            partnerId = 0,
            bpCode = '',
            shippingMethod: { method, shippingCarrier, shippingService, accountNo = '', contactInfo = '' } = {},
            decimalPlace = {},
            vat = '',
            billingCc,
            shippingCc,
            paymentAccount,
          } = {},
        },
      },
    } = props

    this.state = {
      skipPromptOnExit: false,
    }

    const fields = {
      name: {
        type: 'text',
        label: 'Name',
        rules: rules,
        value: name,
      },
      status: {
        type: 'switch',
        label: 'Status',
        value: status === 'ACTIVE',
      },
      partnerId: {
        label: 'Partner ID',
        rules: `${rules}|numeric`,
        value: partnerId,
      },
      bpCode: {
        type: 'text',
        label: 'BP Code',
        value: bpCode,
      },
      groups: {
        type: 'select',
        label: 'Group',
        rules: rules,
        value: groups ? groups[0] : null,
      },
      defaultStore: {
        type: 'select',
        label: 'Default Store',
        rules: rules,
        value: defaultStore ? defaultStore : null,
      },
      paymentAccount: {
        type: 'switch',
        label: 'Use Singapore Accounts',
        value: paymentAccount === 'SG',
      },
      shippingMethod: {
        fields: {
          method: {
            type: 'text',
            label: 'SHIPPING PREFERENCE',
            value: method,
          },
          shippingCarrier: {
            label: 'Shipping Method',
            type: 'select',
            rules: 'required_if:shippingMethod.method,Customer Provided',
            value: shippingCarrier,
          },
          shippingService: {
            label: 'Shipping Service',
            type: 'select',
            value: shippingService,
          },
          accountNo: {
            type: 'text',
            label: 'Account Number',
            value: accountNo,
          },
          contactInfo: {
            type: 'textarea',
            label: 'Additional Information',
            value: contactInfo,
          },
        },
      },
      decimalPlace: {
        fields: {
          decimalPlaceInd: {
            type: 'switch',
            label: 'Active',
            value: decimalPlace.decimalPlaceInd || false,
          },
          noOfDecimalPlace: {
            type: 'number',
            label: 'Number of Decimal Places',
            value: decimalPlace.noOfDecimalPlace || 2,
            validators: [this.validateNoOfDecimalPlace],
          },
        },
      },
      vat: {
        label: 'VAT',
        value: vat,
      },
      billingCc: {
        type: 'textarea',
        label: 'Billing - Email CC List',
        value: billingCc ? billingCc.join('\n') : null,
        validators: [mobxEmailList],
      },
      shippingCc: {
        type: 'textarea',
        label: 'Shipping - Email CC List',
        value: shippingCc ? shippingCc.join('\n') : null,
        validators: [mobxEmailList],
      },
    }
    this.form = new Form({ fields }, { name: 'CustomerForm', hooks: { onSuccess: this.handleSubmit } })

    this.fetchOrderShippingMethods()
  }

  componentWillMount() {
    this.props.commonRepository.getCurrencies()
  }

  componentWillUnmount() {
    this.props.customerRepository.clearCrudData()
  }

  fetchOrderShippingMethods = () => {
    this.props.commonRepository.getCommon('orderShippingMethods', 'orderShippingMethods', {})
  }

  handleCancel = () => {
    this.props.history.push('/customers')
  }

  handleSubmit = form => {
    const values = form.values()
    const {
      customerRepository: {
        CRUD: {
          data: { id = null },
        },
      },
      redirectAfterCreateTo,
    } = this.props

    const isPriorityShipping = values.shippingMethod.method === SHIPPING_METHOD_PRIORITY_SHIPPING
    const payload = {
      ...values,
      status: values.status ? 'ACTIVE' : 'INACTIVE',
      paymentAccount: values.paymentAccount ? 'SG' : null,
      shippingMethod: {
        ...values.shippingMethod,
        shippingCarrier: isNull(values.shippingMethod.shippingCarrier) && {
          id: values.shippingMethod.shippingCarrier.id,
        },
        shippingService: isNull(values.shippingMethod.shippingService) && {
          id: values.shippingMethod.shippingService.id,
        },
        accountNo: isPriorityShipping ? null : values.shippingMethod.accountNo,
        contactInfo: isPriorityShipping ? null : values.shippingMethod.contactInfo,
      },
      decimalPlace: {
        ...values.decimalPlace,
        noOfDecimalPlace: values.decimalPlace.decimalPlaceInd ? values.decimalPlace.noOfDecimalPlace : null,
      },
      billingCc: values.billingCc ? removeEmptyItems(values.billingCc.trim().split('\n')) : undefined,
      shippingCc: values.shippingCc ? removeEmptyItems(values.shippingCc.trim().split('\n')) : undefined,
    }

    if (id) {
      this.props.customerRepository.patch(
        {
          ...payload,
          id,
          action: 'updateAll',
        },
        () => {
          this.setState({ skipPromptOnExit: true })
          this.props.history.push(CUSTOMERS_PATH)
        }
      )
    } else {
      this.props.customerRepository.create(
        {
          ...payload,
        },
        res => {
          this.setState({ skipPromptOnExit: true })

          const redirectPath = redirectAfterCreateTo
            ? `${redirectAfterCreateTo}?customerId=${res.id}`
            : `${CUSTOMERS_PATH}/${res.id}`
          this.props.history.push(redirectPath)
        }
      )
    }
  }

  handleContactsOnSubmit = () => {
    // workaround to force re-toggle prompt
    const skipPromptOnExit = this.state.skipPromptOnExit
    this.setState({ skipPromptOnExit: !skipPromptOnExit }, () => {
      this.setState({ skipPromptOnExit })
    })
  }

  validateNoOfDecimalPlace = ({ form, field }) => {
    const decimalPlaceInd = form.$('decimalPlace.decimalPlaceInd').value
    if (decimalPlaceInd) {
      const decimalValue = toInt(field.value)
      if (isNaN(decimalValue) || decimalValue < 1 || decimalValue > 3) {
        return [false, 'Input value must be within 3 decimal places only.']
      }
    }
    return [true]
  }

  toggleCustomShipping = value => {
    const isPriorityShipping = value === SHIPPING_METHOD_PRIORITY_SHIPPING

    this.form.$('shippingMethod.accountNo').set('rules', null)
    if (isPriorityShipping) {
      this.form.$('shippingMethod.shippingCarrier').resetValidation()
      this.form.$('shippingMethod.accountNo').resetValidation()
      this.form.$('shippingMethod.accountNo').set('disabled', true)
      this.form.$('shippingMethod.contactInfo').set('disabled', true)
    } else {
      this.form.$('shippingMethod.accountNo').set('rules', rules)
      this.form.$('shippingMethod.accountNo').set('disabled', false)
      this.form.$('shippingMethod.contactInfo').set('disabled', false)
    }
  }

  render() {
    const {
      isEdit,
      title,
      breadcrumbLabel,
      actions,
      commonRepository: { Common = {} },
      customerRepository,
      customerContactRepository,
      customerGroupRepository,
      customerAddressRepository,
      customerSpecialProductRepository,
      storeRepository,
      shippingRepository: { reactSelectSearchShippingCarriers, reactSelectSearchShippingServices },
      view,
      onDisplayModeChanged,
    } = this.props

    const {
      CRUD: { errors, loading, loadingFailed, data },
    } = customerRepository
    const {
      orderShippingMethods: { data: orderShippingMethodsData = [] },
    } = toJS(Common)
    const shippingMethod = this.form.$('shippingMethod.method').value
    const isPriorityShipping = shippingMethod === SHIPPING_METHOD_PRIORITY_SHIPPING
    const decimalPlaceInd = this.form.$('decimalPlace.decimalPlaceInd').value
    const shippingCarrier = this.form.$('shippingMethod.shippingCarrier').value
    const selectedOrderShippingMethod = orderShippingMethodsData.find(item => item.value === shippingMethod)
    const selectedOrderShippingMethodId = selectedOrderShippingMethod && selectedOrderShippingMethod.id

    const isSubmitting = isEdit
      ? customerRepository.CRUD.submitting ||
        customerContactRepository.CRUD.submitting ||
        customerAddressRepository.CRUD.submitting ||
        customerSpecialProductRepository.CRUD.submitting
      : customerRepository.CRUD.submitting

    const isPromptOnExit = isSubmitting || (this.form.isDirty && !this.state.skipPromptOnExit)

    return (
      <ContentContainer
        title={title}
        promptOnExit={isPromptOnExit}
        promptOnExitIsConfirm={!isSubmitting}
        breadcrumbLabel={breadcrumbLabel}
        actions={actions}
      >
        <Row>
          <Col xs={4}>
            <FormContainer
              title="Account Information"
              onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
              errors={loadingFailed && errors}
              plain
            >
              <Card title="Account Information">
                <Input
                  field={this.form.$('name')}
                  title={this.form.$('name').value}
                  inputClassName="ellipsis"
                  endAdornment={
                    <Checkbox
                      field={this.form.$('status')}
                      plain
                      className="ml-1 mt-4"
                      disabled={!allowedToAppAdminRole()}
                    />
                  }
                  readOnly={!allowedToAppAdminRole()}
                />
                <Row>
                  <Col lg={6}>
                    <Input
                      field={this.form.$('partnerId')}
                      readOnly={!allowedToAppAdminRole()}
                      inputClassName="text-left"
                      onBeforeInput={event => {
                        if (!/^[0-9]*$/.test(event.data)) {
                          event.preventDefault()
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input field={this.form.$('bpCode')} readOnly={!allowedToAppAdminRole()} />
                  </Col>
                </Row>
                <ReactSelect
                  field={this.form.$('groups')}
                  customLabelKey={['name', 'id']}
                  serverSide
                  search={customerGroupRepository.reactSelectSearch}
                  options={{
                    isMulti: false,
                    valueKey: 'id',
                    labelKey: 'name',
                    defaultOptions: true,
                  }}
                  readOnly={!allowedToAppAdminRole()}
                />
                <ReactSelect
                  field={this.form.$('defaultStore')}
                  customLabelKey={['name', 'id']}
                  serverSide
                  search={payload => storeRepository.reactSelectSearch({ ...payload, showDisabled: false })}
                  options={{
                    valueKey: 'id',
                    labelKey: 'name',
                    defaultOptions: true,
                  }}
                  readOnly={!allowedToAppAdminRole()}
                />
                <Input field={this.form.$('billingCc')} readOnly={!allowedToAppAdminRole()} rows="2" />
                <Input field={this.form.$('shippingCc')} readOnly={!allowedToAppAdminRole()} rows="2" />
                <Checkbox field={this.form.$('paymentAccount')} plain disabled={!allowedToAppAdminRole()} />
                <RadioGroup
                  field={this.form.$('shippingMethod.method')}
                  radios={orderShippingMethodsData}
                  inline
                  onChange={selected => {
                    this.toggleCustomShipping(selected)
                    if (shippingMethod !== selected) {
                      this.form.$('shippingMethod.shippingCarrier').set(null)
                      this.form.$('shippingMethod.shippingService').set(null)
                    }
                  }}
                  disabled={!allowedToAppAdminRole()}
                />
                <ReactSelect
                  field={this.form.$('shippingMethod.shippingCarrier')}
                  serverSide
                  innerKey={`shippingCarrier${selectedOrderShippingMethodId}${(shippingMethod || '').trim()}`}
                  search={params => {
                    return reactSelectSearchShippingCarriers(selectedOrderShippingMethodId, params)
                  }}
                  options={{
                    valueKey: 'value',
                    labelKey: 'label',
                    defaultOptions: true,
                  }}
                  onChange={selected => {
                    if (shippingCarrier && selected) {
                      if (shippingCarrier.value !== selected.value) {
                        this.form.$('shippingMethod.shippingService').set(null)
                      }
                    }
                  }}
                  readOnly={!allowedToAppAdminRole()}
                />
                <ReactSelect
                  field={this.form.$('shippingMethod.shippingService')}
                  serverSide
                  innerKey={`shippingService${selectedOrderShippingMethodId}${
                    shippingCarrier && shippingCarrier.value
                  }`}
                  search={params =>
                    reactSelectSearchShippingServices(
                      selectedOrderShippingMethodId,
                      shippingCarrier && shippingCarrier.id,
                      params
                    )
                  }
                  options={{
                    valueKey: 'value',
                    labelKey: 'label',
                    defaultOptions: true,
                  }}
                  readOnly={!allowedToAppAdminRole()}
                />
                <Input
                  field={this.form.$('shippingMethod.accountNo')}
                  disabled={isPriorityShipping}
                  readOnly={!allowedToAppAdminRole()}
                />
                <Input
                  field={this.form.$('shippingMethod.contactInfo')}
                  disabled={isPriorityShipping}
                  readOnly={!allowedToAppAdminRole()}
                />
                <Input field={this.form.$('vat')} />
                <br />
                <Row>
                  <Col lg={6}>
                    <small>
                      <strong>DECIMAL PLACES</strong>
                    </small>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <Checkbox
                      field={this.form.$('decimalPlace.decimalPlaceInd')}
                      plain
                      className="ml-1 mt-4"
                      disabled={!allowedToAppAdminRole()}
                    />
                  </Col>
                </Row>
                {!decimalPlaceInd && (
                  <ReadOnlyField label={this.form.$('decimalPlace.noOfDecimalPlace').label} disabled />
                )}
                {decimalPlaceInd && (
                  <Input field={this.form.$('decimalPlace.noOfDecimalPlace')} readOnly={!allowedToAppAdminRole()} />
                )}
              </Card>
              <Visible toAppAdminRole>
                <div className="d-flex justify-content-end">
                  <FormActions
                    variant="contained"
                    loading={loading}
                    loadingMessage={`Saving please wait...`}
                    confirmLabel={`Save`}
                    confirmIcon={`save`}
                    onConfirm={() => {
                      this.form.submit()
                    }}
                    onCancel={this.handleCancel}
                    hideConfirm={!allowedToOrderRole()}
                  />
                </div>
              </Visible>
            </FormContainer>
          </Col>
          {data.id && view && (
            <Col xs={8}>
              {view === 'addresses' && (
                <Addresses
                  customer={toJS(data)}
                  onDisplayModeChanged={onDisplayModeChanged}
                  onSubmit={() => this.handleContactsOnSubmit()}
                />
              )}
              {view === 'contacts' && (
                <Contacts
                  customer={toJS(data)}
                  onDisplayModeChanged={onDisplayModeChanged}
                  onSubmit={() => this.handleContactsOnSubmit()}
                />
              )}
              {view === 'products' && <SpecialProducts customer={toJS(data)} />}
            </Col>
          )}
        </Row>
      </ContentContainer>
    )
  }
}

export default decorate(
  inject(
    'commonRepository',
    'customerRepository',
    'customerGroupRepository',
    'storeRepository',
    'shippingRepository'
  )(observer(CustomerForm)),
  {}
)
